(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("React"), require("mobx"), require("mobxReact"), require("sbJsExtends"), require("sbRespBlockLib"), require("sbDataLayer"), require("sbInternalMsgBus"), require("ReactDOM"));
	else if(typeof define === 'function' && define.amd)
		define("betting-history", ["React", "mobx", "mobxReact", "sbJsExtends", "sbRespBlockLib", "sbDataLayer", "sbInternalMsgBus", "ReactDOM"], factory);
	else if(typeof exports === 'object')
		exports["betting-history"] = factory(require("React"), require("mobx"), require("mobxReact"), require("sbJsExtends"), require("sbRespBlockLib"), require("sbDataLayer"), require("sbInternalMsgBus"), require("ReactDOM"));
	else
		root["betting-history"] = factory(root["React"], root["mobx"], root["mobxReact"], root["sbJsExtends"], root["sbRespBlockLib"], root["sbDataLayer"], root["sbInternalMsgBus"], root["ReactDOM"]);
})(window, function(__WEBPACK_EXTERNAL_MODULE__0__, __WEBPACK_EXTERNAL_MODULE__1__, __WEBPACK_EXTERNAL_MODULE__2__, __WEBPACK_EXTERNAL_MODULE__3__, __WEBPACK_EXTERNAL_MODULE__6__, __WEBPACK_EXTERNAL_MODULE__9__, __WEBPACK_EXTERNAL_MODULE__10__, __WEBPACK_EXTERNAL_MODULE__60__) {
return 